<!--
File: Regions.vue
Description: show list of regions entered in the DB.
-->
<template>
  <base-data-table v-if="eligible" :loading="showSpinner" :pagination="pagination"
    :btnAddAllowed="isBtnAllowed('AddButton')" :from="from" :to="to" :total="total" @add-item="viewItem()">

    <template #header-filters>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <search-input v-model="searchQuery" />
      </div>
    </template>

    <template #table-rows>
      <md-table class="paginated-table table-striped table-hover" :value="queriedData" :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
        <md-table-empty-state :md-label="$t('label.no_data')"
          :md-description="$t('messages.select_another_criteria')" />

        <md-table-row>
          <md-table-head v-for="header in headers" :key="header.value" :md-sort-by="header.value">
            {{ $t(header.text) }}
          </md-table-head>
        </md-table-row>

        <md-table-row slot="md-table-row" slot-scope="{item}"
          :class="{ blinking: item[idFieldName] === highlightedRow }"
          :id="item[idFieldName] === highlightedRow ? 'highlightedRow' : null">

          <md-table-cell v-for="header in headers" :key="header.value" :md-label="$t(header.text)"
            :md-sort-by="header.sortable ? header.value : null" :md-numeric="header.format === 'number'">
            <template v-if="header.condition">
              <a href='' @click.stop.prevent="header.onClick(item.region_id)"> {{ item[header.value] }} </a>
            </template>
            <template v-else>
              {{ item[header.value] }}
            </template>
          </md-table-cell>

          <md-table-cell :md-label="$t('tables.actions')">
            <table-actions :btnEditAllowed="isBtnAllowed('EditButton')" :btnDeleteAllowed="isBtnAllowed('DeleteButton')"
              @edit="viewItem(item[idFieldName])" @delete="deleteItem(item[idFieldName], item.description)" />
          </md-table-cell>
        </md-table-row>
      </md-table>
    </template>

    <template #editing-dialog>
      <EditForm v-if="showDetailsDlg" :regionId="currentId" @close="showDetailsDlg = false" />
    </template>
  </base-data-table>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import BaseTableMixin from '@/mixins/BaseTableMixin'
import BaseDataTable from "@/pages/Components/BaseDataTable.vue"
import SearchInput from "@/pages/Components/SearchInput.vue"
import TableActions from "@/pages/Components/TableActions.vue"
import EditForm from './RegionEditForm.vue'

export default {
  name: 'regions-list',
  mixins: [BaseTableMixin],

  data() {
    return {
      // Переопределяем только специфичные для компонента данные
      formName: 'Regions',
      idFieldName: 'region_id',
      propsToSearch: ['region_description'],
      currentSort: 'region_description',

      historyMapping: {
        currentSort: 'description',
        currentSortOrder: 'asc',
        pagination: this.pagination,  // { perPage: 10, currentPage: 1 },
        searchQuery: ''
      }
    }
  },

  components: {
    EditForm,
    BaseDataTable,
    SearchInput,
    TableActions
  },

  methods: {
    ...mapActions({
      clearList: 'CLEAR_REGION_LIST', // Called from the BaseTableMixin's mount()
      loadList: 'LOAD_REGION_LIST',
      delete: 'DELETE_REGION',
    }),

    handleSectionsClick(item) {
      this.selectedRegion = item;
      this.saveHistory2(['selectedRegion'], 'Sections');
      this.$router.push('/inventory_data/sections');
    },
  },

  computed: {
    ...mapState({
      tableData: (state) => state.RoadNetwork.region_list,
    }),

    headers() {
      return [
        { text: "road_network.region", value: "region_description", sortable: true },
        { text: "road_network.region_key", value: "region_key", sortable: true },
        { text: "road_network.regional_office", value: "regional_dep", sortable: true },
        {
          text: "road_network.section_count", value: "section_count", sortable: true, condition: "item.section_count > 0",
          onClick: this.handleSectionsClick, format: "number"
        },
      ]
    },
  }
}
</script>
<style lang="scss" scoped>
@import '@/mixins/BaseTable.scss';
</style>
