<!--
File: RegionEditForm.vue
Description: show details of a selected road section as a dialog window.
-->
<template>
  <modal v-if="eligible" :title="screenTitle" :form="formName" :errCount="errors.count()"
    :hasUnsavedChanges="hasUnsavedChanges" @close="$emit('close')" @save="validate">

    <template slot='body'>
      <div class="md-layout-item md-small-size-100 md-size-100">
        <md-field :class="getClass('region_key')">
          <label for="region_key">{{ $t('road_network.region_key') }}</label>
          <md-input id="region_key" v-model="region_key" type="text" data-vv-name="region_key" required
            v-validate="modelValidations.region_key" />
        </md-field>

        <md-field :class="getClass('region_description')">
          <label for="region_description">{{ $t('stdCols.name_ru') }}</label>
          <md-input id="region_description" v-model="region_description" type="text" data-vv-name="region_description"
            required v-validate="modelValidations.region_description" />
        </md-field>
        <md-field :class="getClass('region_description_en')">
          <label for="region_description_en">{{ $t('stdCols.name_en') }}</label>
          <md-input id="region_description_en" v-model="region_description_en" type="text"
            data-vv-name="region_description_en" required v-validate="modelValidations.region_description_en" />
        </md-field>

        <md-field :class="getClass('regional_dep')">
          <label for="regional_dep">{{ $t('road_network.regional_office') + ' (RU)' }}</label>
          <md-input id="regional_dep" v-model="regional_dep" type="text" data-vv-name="regional_dep" required
            v-validate="modelValidations.regional_dep" />
        </md-field>
        <md-field :class="getClass('regional_dep_en')">
          <label for="regional_dep_en">{{ $t('road_network.regional_office') + ' (EN)' }}</label>
          <md-input if="regional_dep_en" v-model="regional_dep_en" type="text" data-vv-name="regional_dep_en" required
            v-validate="modelValidations.regional_dep_en" />
        </md-field>
        <md-field style="width: 50px; top: -7px">
          <md-switch v-model="active" id="active">{{ $t('road_network.active') }}</md-switch>
        </md-field>
      </div>
    </template>
  </modal>
</template>
<script>
import { mapActions } from 'vuex'
import { Modal } from '@/pages/Components'
import { savedMessage } from '@/mixins/messagesMixin'
import permissions from "@/mixins/permissionsMixin"

export default {
  name: 'region-edit-form',
  mixins: [permissions],

  data() {
    return {
      formName: 'RegionEditForm',
      eligible: false,

      fk_rn: 1,
      region_key: null,
      region_description: null,
      region_description_en: null,
      regional_dep_en: null,
      regional_dep: null,
      active: null,

      initialFormState: null,
      modelValidations: {
        region_key: { required: true, min: 3 },
        region_description: { required: true, min: 3 },
        region_description_en: { required: true, min: 3 },
        regional_dep_en: { required: true, },
        regional_dep: { required: true, min: 3 },
      }
    }
  },

  props: {
    regionId: null
  },

  components: {
    Modal
  },

  async mounted() {
    // Check if we are eligible to view the form
    this.eligible = await this.checkIfScreenAllowed()
    if (!this.eligible) {
      this.$emit('close')
      return
    };

    if (this.regionId) { // Load data on existing region
      const theRegion = await this.loadRegion(this.regionId)
      this.region_key = theRegion.region_key
      this.region_description = theRegion.region_description
      this.region_description_en = theRegion.region_description_en
      this.regional_dep = theRegion.regional_dep
      this.regional_dep_en = theRegion.regional_dep_en
      this.active = theRegion.active
    }
    this.$nextTick(async () => {
      this.initialFormState = this.getCurrentState;
      await this.$validator.validateAll();
    });
  },

  methods: {
    ...mapActions({
      loadRegion: 'LOAD_REGION_BY_ID',
      addItem: 'ADD_NEW_REGION',
      editItem: 'UPDATE_REGION',
      highlightRow: 'HIGHLIGHT_ROW'
    }),

    async validate() {
      const isValid = await this.$validator.validateAll()
      if (!isValid) return

      const theItem = this.getCurrentState;

      let errDesc = '';
      let newId;
      const action = !this.regionId ? this.addItem : this.editItem;
      const payload = !this.regionId ? theItem : { id: this.regionId, theItem };
      try {
        const res = await action(payload)
        newId = res?.region_id;
      } catch (err) {
        errDesc = err.message || this.$t('messages.unknown_error')
      }

      this.$nextTick(() => this.$validator.reset())
      this.$emit('close')
      await savedMessage(errDesc, this.$t('road_network.region'), this.region_description, this.region_description_en)
      this.highlightRow(newId)
    },
  },

  computed: {
    screenTitle() {
      return this.regionId ? this.$t('screen_titles.regions_upd') : this.$t('screen_titles.regions_add')
    },

    getCurrentState() {
      return {
        fk_rn: this.fk_rn,
        region_key: this.region_key,
        region_description: this.region_description,
        region_description_en: this.region_description_en,
        regional_dep: this.regional_dep,
        regional_dep_en: this.regional_dep_en,
        active: this.active
      }
    },

    hasUnsavedChanges() {
      if (!this.initialFormState) return false

      return this.fk_rn !== this.initialFormState.fk_rn ||
        this.region_key !== this.initialFormState.region_key ||
        this.region_description !== this.initialFormState.region_description ||
        this.region_description_en !== this.initialFormState.region_description_en ||
        this.regional_dep !== this.initialFormState.regional_dep ||
        this.regional_dep_en !== this.initialFormState.regional_dep_en ||
        this.active !== this.initialFormState.active
    }
  }
}
</script>